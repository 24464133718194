import SparkMD5 from "spark-md5";

interface QueuedChunk {
  blobData: ArrayBuffer;
  // blobData is a slice of the original file, with bytes [startByte, endByte - 1].
  startByte: number;
  endByte: number;
}

/**
 * Chunked MD5 calculation, correctly handling out-of-order chunks by queueing them.
 */
export class ChunkedMD5 {
  #spark = new SparkMD5.ArrayBuffer();
  #queue: QueuedChunk[] = [];
  #nextStartByte = 0;

  public append(blobData: ArrayBuffer, startByte: number, endByte: number): void {
    if (startByte === this.#nextStartByte) {
      this.#spark.append(blobData);
      this.#nextStartByte = endByte;
      if (this.#queue.length > 0) {
        this.#processQueue();
      }
    } else {
      // TODO: Temp logging, until we've added proper tests.
      console.log("Out of order chunk detected:", startByte, "nextStartByte would be:", this.#nextStartByte,
        "new queue length", this.#queue.length + 1);
      this.#queue.push({ blobData, startByte, endByte });
    }
  }

  public end(): string {
    if (this.#queue.length > 0) {
      throw new Error(`ChunkedMD5: Cannot calculate hash. Missing chunk with startByte ${this.#nextStartByte}.`);
    }
    return this.#spark.end();
  }

  #processQueue(): void {
    let chunk: QueuedChunk | undefined;
    // eslint-disable-next-line no-constant-condition -- We use a "break" below.
    while (true) {
      chunk = undefined;
      for (let i = 0; i < this.#queue.length; i++) {
        if (this.#queue[i].startByte === this.#nextStartByte) {
          chunk = this.#queue[i];
          this.#queue.splice(i, 1);
          break;
        }
      }

      if (!chunk) {
        break;
      }
      this.#spark.append(chunk.blobData);
      this.#nextStartByte = chunk.endByte;
    }
  }
}
