import { Box } from "@mui/material";
import { ReactSetStateFunction } from "@custom-types/types";
import { FaroSelect } from "@components/common/faro-select/faro-select";
import { FaroMenuItem } from "@components/common/faro-select/faro-menu-item";
import { LabelWithHelp } from "@components/common/label-with-help";
import { useGroups } from "@hooks/use-groups";
import { withEllipsis } from "@styles/common-styles";
import { CoreAPITypes } from "@stellar/api-logic";

interface Props {
  /** Already selected group ID if there is any */
  selectedGroupId: string;

  /** Callback function to update the state */
  onChange: ReactSetStateFunction<string>;

  /** Whether the field is required or not */
  isRequired?: boolean;

  /** Whether should list only groups where current user is group managers */
  shouldShowGroupManagersOnly?: boolean;
}

/** Renders a dropdown to select a group */
export function SelectGroup({
  selectedGroupId,
  onChange,
  isRequired = true,
  shouldShowGroupManagersOnly = false,
}: Props): JSX.Element {
  let companyGroups = useGroups();

  if (shouldShowGroupManagersOnly) {
    companyGroups = companyGroups.filter(
      (group) => group.role === CoreAPITypes.EUserCompanyRole.companyManager
    );
  }

  return (
    <>
      <LabelWithHelp title="Group" isRequired={isRequired} />
      <FaroSelect
        // Don't pass in the value if the groups are not loaded yet to avoid warnings in the console
        value={companyGroups.length ? selectedGroupId : ""}
        size="small"
        variant="outlined"
        onChange={onChange}
        placeholder="Please select a group..."
      >
        {companyGroups.map((companyGroup) => (
          <FaroMenuItem value={companyGroup.id} key={companyGroup.id}>
            <Box
              component="div"
              sx={{
                ...withEllipsis,
                maxWidth: "80vw",
              }}
            >
              <var>{companyGroup.name}</var>
            </Box>
          </FaroMenuItem>
        ))}
      </FaroSelect>
    </>
  );
}
