import { Box, Typography } from "@mui/material";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { IntegrationTile } from "@pages/integrations/integration-tile";
import { sphereColors } from "@styles/common-colors";
import { TilesContainer } from "@components/common/tiles-container";
import {
  IntegrationActions,
  IntegrationSections,
} from "@pages/integrations/integrations-types";

interface Props {
  /** Section title */
  title: string;

  /** List id of integration Ids */
  integrations: SphereDashboardAPITypes.IntegrationId[];

  /** Callback function to enable or disable an integration */
  onIntegrationAction(
    integrationId: SphereDashboardAPITypes.IntegrationId
  ): void;

  /** The text to be shown for action button */
  actionButtonText: IntegrationActions;

  /** Callback to get the name of the project integration */
  getProjectIntegrationName?: (integrationId: SphereDashboardAPITypes.IntegrationId) => string | undefined;
}

/** Renders a section combining multiple integration types */
export function IntegrationSection({
  title,
  integrations,
  onIntegrationAction,
  actionButtonText,
  getProjectIntegrationName,
}: Props): JSX.Element {

  return (
    <Box component="div" sx={{ my: "25px" }}>
      <Typography
        sx={{
          color: sphereColors.gray800,
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "600",
        }}
      >
        {title}
      </Typography>

      <TilesContainer>
        {integrations.map((integrationId) => (
          <IntegrationTile
            key={integrationId}
            icon={IntegrationSections[integrationId].icon}
            actionButtonText={actionButtonText}
            title={IntegrationSections[integrationId].displayName}
            description={IntegrationSections[integrationId].description}
            onIntegrationAction={() => onIntegrationAction(integrationId)}
            projectName={getProjectIntegrationName && getProjectIntegrationName(integrationId)}
          />
        ))}
      </TilesContainer>
    </Box>
  );
}
